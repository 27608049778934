.hf-news-article{border-top:5px solid #ee9435;padding-top:32px}.hf-news-article .title{-webkit-box-decoration-break:clone;-o-box-decoration-break:clone;box-decoration-break:clone;display:inline;line-height:3rem;padding-left:0;font-size:2.5rem;background-color:transparent !important;color:#363636}.hf-news-article .title:not(.is-spaced)+.subtitle{margin-top:0}.hf-news-article .hf-is-lead{font-family:"Roboto Slab","Georgia",serif;font-size:1.25rem}.hf-news-article .hf-news-tagline.tags .tag{background-color:transparent !important;margin-bottom:1px !important;margin-right:0 !important}.hf-news-article .hf-news-tagline.tags .tag:first-child{padding-left:0}.hf-news-article .hf-news-tagline.tags .tag:not(:first-child){border-left:1px solid #dbdbdb}.hf-news-article .hf-news-tagline.tags .tag.hf-news-attribute-tag{font-weight:bold}

figure[data-v-f15b1d7c]{margin-right:0.75em}.subtitle[data-v-f15b1d7c]{margin-bottom:.75rem}.subtitle.tags[data-v-f15b1d7c]{margin-top:-1.25em}

.hf-news-headline .title{background:transparent;line-height:1.9rem;-webkit-box-decoration-break:clone;-o-box-decoration-break:clone;box-decoration-break:clone;display:inline}.hf-news-headline .title a{font-family:"Roboto Slab","Georgia",serif}.hf-news-headline .hf-news-team-picture{padding-right:.75rem}.hf-news-headline .hf-news-team-picture .hf-news-headline-player-picture{margin-left:6px;margin-right:7px}.hf-news-headline .hf-news-tagline.tags .tag{background-color:transparent !important;margin-bottom:1px !important;margin-right:0 !important}.hf-news-headline .hf-news-tagline.tags .tag:first-child{padding-left:0}.hf-news-headline .hf-news-tagline.tags .tag:not(:first-child){border-left:1px solid #dbdbdb}.hf-news-headline .hf-news-tagline.tags .tag.hf-news-attribute-tag{font-weight:bold}

.hf-news-headline .hf-is-lead[data-v-e2a0d278]{font-family:"Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue","Helvetica","Arial",sans-serif;font-size:1rem;letter-spacing:1px}

.hf-news-columns .column:nth-child(even){background:#ededed}


/*# sourceMappingURL=hf-index~hf-news.bbfc8b68.css.map*/